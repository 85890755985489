/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import groupBy from "lodash/groupBy";
import { FC, memo } from "react";
import { Grid, jsx, Themed } from "theme-ui";

import type { AllTheatersQuery } from "../../../__generated__/gatsby.types";
import Item from "./Item";
import ItemGroup from "./ItemGroup";
import { Theater } from "./types";
import Wrapper from "./Wrapper";

export type Props = {
  data?: AllTheatersQuery | null;
};

const AllTheatersComponent: FC<Props> = ({ data }) => {
  const theaterGroups: Record<string, Theater[]> = groupBy(
    data?.allTheater.nodes,
    (theater) => {
      return theater.practicalInfo?.location?.state || "";
    }
  );

  const theaterGroupsSorted: string[] = Object.keys(theaterGroups).sort(
    (label1, label2) => {
      if (label1 === "") return 1;
      if (label2 === "") return -1;
      return label1.localeCompare(label2);
    }
  );

  return (
    <Wrapper>
      {theaterGroupsSorted.map((label) => {
        const group: Theater[] = theaterGroups[label].sort(
          (theater1, theater2) =>
            (theater1.name || "").localeCompare(theater2.name || "")
        );

        return (
          <ItemGroup key={label}>
            {!!label && (
              <div sx={{ marginBottom: 3 }}>
                <Themed.h3 sx={{ textTransform: "uppercase" }}>
                  {label}
                </Themed.h3>
              </div>
            )}
            <Grid
              gap={responsive({ xs: 4, xl: 5 })}
              columns={responsive({ xs: "1fr", lg: "1fr 1fr" })}
            >
              {group.map((theater) => (
                <Item key={theater.id} theater={theater} />
              ))}
            </Grid>
          </ItemGroup>
        );
      })}
    </Wrapper>
  );
};

export default memo(AllTheatersComponent);
