import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  details: {
    id: "theaters.card.theater.details-label",
    defaultMessage: "More details",
  },
});

export default messages;
