/** @jsx jsx */
import { CDNImage, responsive } from "@boxoffice/screenplay";
import {
  AllTheatersItemProps,
  allTheatersMessages,
} from "gatsby-theme-theaters";
import { FC, memo } from "react";
import Link from "shared/components/Link";
import TheaterAddress from "shared/components/TheaterAddress";
import { getTinyColor } from "shared/helpers/colors";
import useIntl from "shared/helpers/i18n/useIntl";
import { AspectRatio, Grid, jsx, Themed } from "theme-ui";

const AllTheatersItem: FC<AllTheatersItemProps> = ({ theater }) => {
  const { formatMessage } = useIntl();

  const picture = theater.practicalInfo?.images?.[0];

  return (
    <Grid columns={responsive({ xs: "1fr", lg: "1fr 1fr" })} gap={4}>
      <div>
        <AspectRatio
          ratio={16 / 9}
          sx={{
            backgroundColor: "rgba(0,0,0,.25)",
            borderRadius: "medium",
            overflow: "hidden",
          }}
        >
          <CDNImage
            src={picture?.url}
            alt={theater.name || ""}
            width={352}
            height={Math.ceil(352 / (16 / 9))}
            fit={"cover"}
            sx={{ width: "100%" }}
          />
        </AspectRatio>
      </div>
      <div>
        <div sx={{ marginBottom: 3 }}>
          <Themed.h2 sx={{ fontSize: responsive({ xs: 4, lg: 4 }) }}>
            {theater.name}
          </Themed.h2>
        </div>
        <div sx={{ marginBottom: 3 }}>
          <TheaterAddress location={theater?.practicalInfo?.location} />
        </div>
        <div
          sx={{
            borderTopStyle: "solid",
            borderTopWidth: "small",
            borderTopColor: (theme) => {
              const bg = getTinyColor(theme.rawColors?.background);
              return bg.isLight()
                ? bg.shade(15).toHexString()
                : bg.tint(15).toHexString();
            },
            paddingTop: 3,
          }}
        >
          <Link sx={{ variant: "buttons.primary" }} to={theater.path}>
            {formatMessage(allTheatersMessages.details)}
          </Link>
        </div>
      </div>
    </Grid>
  );
};

export default memo(AllTheatersItem);
