/** @jsx jsx */
import { graphql } from "gatsby";
import { AllTheaters } from "gatsby-theme-theaters";
import React, { memo } from "react";
import usePageContext from "shared/hooks/usePageContext";
import { jsx } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetLocations" }>;
}

const LocationsWidget: React.FC<Props> = ({
  isFirst,
  isLast,
  isNested,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  const pageContext = usePageContext();

  if (pageContext.isSingleLocation) {
    return null;
  }

  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <AllTheaters />
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetLocations on WidgetLocations {
    id
    __typename
  }
`;

export default memo(LocationsWidget);
