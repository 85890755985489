import { graphql, useStaticQuery } from "gatsby";
import React, { memo } from "react";

import type { AllTheatersQuery } from "../../../__generated__/gatsby.types";
import AllTheatersComponent from "./Component";

const AllTheaters: React.FC<Record<string, unknown>> = () => {
  const data = useStaticQuery<AllTheatersQuery>(graphql`
    query AllTheaters {
      allTheater(sort: { order: ASC, fields: name }) {
        nodes {
          id
          name
          path
          practicalInfo {
            phone
            location {
              address
              city
              state
              zip
            }
            email
            images {
              url
            }
          }
        }
      }
    }
  `);

  return <AllTheatersComponent data={data} />;
};

export default memo(AllTheaters);
