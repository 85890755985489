/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import React, { memo } from "react";
import { jsx } from "theme-ui";

const ItemGroup: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div
      sx={{
        borderBottomColor: "highlight",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        paddingY: responsive({ xs: 4, md: 5 }),
        ":first-of-type": {
          paddingTop: 0,
        },
        ":last-of-type": {
          borderBottom: "none",
        },
      }}
    >
      {children}
    </div>
  );
};

export default memo(ItemGroup);
